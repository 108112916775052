import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Name from '../images/name.svg'
import Address from '../images/address.svg'
import Phone from '../images/phone.svg'
import Mail from '../images/mail.svg'
import Website from '../images/website.svg'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const contact = () => {
  return (
    <Layout>
      <Seo title="Contact | Ronak Electronics" />
      <Wrapper>
        <Container>
          <Flex>
            <Map>
              <a href="https://www.google.com/maps/place/28%C2%B021'17.6%22N+77%C2%B017'17.3%22E/@28.3550503,77.2820772,14.68z/data=!4m5!3m4!1s0x0:0x0!8m2!3d28.3548758!4d77.2881322?hl=en">
                <StaticImage src="../images/map.png" alt="map" quality={100} />
              </a>
            </Map>
            <Details>
              <h2>Contact Details</h2>
              <Grid>
                <div>
                  <Icon>
                    <img src={Name} alt="" />
                  </Icon>
                  <p>Mr. Prem Verma (Proprietor)</p>
                </div>

                <div>
                  <Icon>
                    <img src={Address} alt="" />
                  </Icon>
                  <p>
                    House No. 10143, Sanjay Colony, Sector - 23,
                    Faridabad-121005, Haryana, India
                  </p>
                </div>

                <div>
                  <Icon>
                    <img src={Phone} alt="" />
                  </Icon>
                  <p>9810478208</p>
                </div>

                <div>
                  <Icon>
                    <img src={Mail} alt="" />
                  </Icon>
                  <p>ronakelectrical98@gmail.com</p>
                </div>

                <div>
                  <Icon>
                    <img src={Website} alt="" />
                  </Icon>
                  <p>www.abc.com</p>
                </div>
              </Grid>
            </Details>
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default contact

const Wrapper = styled.div``

const Container = styled.div`
  padding: 50px 0;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1200px) {
    padding: 30px 15px;
  }
`

const Flex = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }
`

const Map = styled.div`
  width: 500px;
  /* height: 400px; */
  background: gainsboro;

  @media (max-width: 550px) {
    width: 100%;
  }
`

const Details = styled.div`
  h2 {
    margin-top: 0;
  }
  @media (max-width: 550px) {
    h2 {
      margin-top: 20px;
    }
  }
`

const Grid = styled.div`
  grid-gap: 10px;
  grid-template-columns: 1fr;
  display: grid;

  > div {
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: flex-start;
    grid-gap: 20px;

    > div,
    p {
      border: 1px solid rgb(0 0 0 / 10%);
      border-radius: 4px;
      margin: 0;
    }

    p {
      padding: 10px;
    }

    div {
      padding: 7px;
    }
  }
`

const Icon = styled.div`
  img {
    height: 30px;
    width: 30px;
  }
`
